import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import { Button } from '@material-ui/core'
import RolePermissions from '../components/nonreuseable/RolesPermissions'
import OrgPermissions from '../components/nonreuseable/OrgPermissions'
import SystemPermissions from '../components/nonreuseable/SystemPermissions'
import UserPermissionsSearch from '../components/nonreuseable/UserPermissionsSearch'
import { useStores } from '../hooks/useStores'
import { useOktaAuth } from '@okta/okta-react'

const SYSTEM = 'SYSTEM'
const ORG = 'ORG'
const ROLE = 'ROLE'
const CHECK = 'CHECK'

const Permissions = observer(() => {
  const { oktaAuth } = useOktaAuth()
  const [ view, setView ] = useState(CHECK)
  const [prevPage, setPrevPage] = useState(null);
  const { permissionsStore, organizationStore } = useStores()

  useEffect(() => {
    organizationStore.setSelected('Hi Marley')
    oktaAuth.token.getUserInfo().then((info)=> { 
      permissionsStore.getLoggedInUsersPermissions(info.email)
    })
  },[])

  const switchView = page => {
    if (page !== prevPage) {
      setPrevPage(page);
      permissionsStore.setIncludes([])
      permissionsStore.setExcludes([])
      permissionsStore.setRolePermissions([])
      permissionsStore.setUserPermissions([])
      setView(page)
    }
  }

  const fetchedLoggedInUser = permissionsStore.loggedInUserFetched
  const hasEditAbility = fetchedLoggedInUser && permissionsStore.loggedInUserPermissions.includes('PERMISSION_EDIT_ACCESS')
  const loggedInUser = permissionsStore.user
  return (
    <div className="permissions-view">
      <div className="banner">
        <Button variant="contained" className="perm-button" onClick={() => switchView(SYSTEM)}> System-Wide Permissions</Button>
        <Button variant="contained" className="perm-button" onClick={() => switchView(ORG)}> Organization Permissions</Button>
        <Button variant="contained" className="perm-button" onClick={() => switchView(ROLE)}> Role Permissions </Button>
        <Button variant="contained" className="perm-button" onClick={() => switchView(CHECK)}> User Check </Button>
      </div>
      <div className='center-box'>
      {
        !fetchedLoggedInUser ? <div> Looking up logged in user's data </div> :
        !hasEditAbility ? <div className='missingPermissionMessage'>{`The logged in user ${loggedInUser.firstName} ${loggedInUser.lastName} does not have the permission 'PERMISSION_EDIT_ACCESS' to access this page. Grant this user the ADMIN role in the Hi Marley org for this environment`}</div> :
        view === SYSTEM  ? <SystemPermissions/>
        : view === ORG  ? <OrgPermissions/>
        : view === ROLE ? <RolePermissions/>
        : view === CHECK ? <UserPermissionsSearch/>
        : <div/>
      }
      </div>
    </div>
  )
})

export default Permissions