import { isArray } from "lodash"
import { Requests } from "./Requests"
import endpoints from '../../endpoints'

const PERMISSIONS_BASE_URL = endpoints.MOBILE_APP_API

export default class PermissionsHelper {

  static async getOrgPermissions(org){
    let encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/permissions`
    let res = await Requests.authRequest(url, 'GET')
    return res
  }

  static async updateOrgPermissions(org, permissions, orgId, userId, changes){
    if (!userId || !org || !orgId) {
      throw new Error('Missing required parameters');
    }
    let encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/permissions`
    try {
      await Requests.authRequest(url, 'PATCH', { ...permissions, orgName: org, orgId, userId, changes })
    } catch (error) {
      console.error('Error updating org permissions', error)
    }
  }

  static async getRolePermissions(org){
    const encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/roles/permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    const rolePermissions = res.map( roleObj => {
      return {
        role: roleObj.groupName,
        permissions: roleObj.groupPermissionList,
        orgName: roleObj.organizationId
      }
    })
    return {rolePermissions}
  }

  static async updateRolePermissions(org, role, permissions, orgId, userId){
    if (!userId || !org || !orgId) {
      throw new Error('Missing required parameters');
    }
    const encodedOrg = encodeURIComponent(org)
    const encodedRole = encodeURIComponent(role)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/roles/${encodedRole}/permissions`
    try {
      await Requests.authRequest(url, 'POST', { ...permissions, orgName: org, orgId, userId })
    } catch (error) {
      console.error('Error updating role permissions', error)
    }
  }

  static async getUserPermissions(org, userId){
    const encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/users/${userId}/permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    return res
  }

  static async getSystemPermissions(){
    const url = `${PERMISSIONS_BASE_URL}/system-permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    return res
  }

  static async createOrUpdateSystemPermission(permission, data = {}){
    const { orgName, orgId, userId } = data;
    if (!userId || !orgName || !orgId) {
      throw new Error('Missing required parameters');
    }
    const url = `${PERMISSIONS_BASE_URL}/system-permissions`
    let res = null;
    try {
      res = await Requests.authRequest(url, 'POST', { ...permission, orgName, orgId, userId })
    } catch (error) {
      console.error('Error creating/updating system permission', error)
    }
    console.log('created permission')
    console.log(res)
    if (!res || !isArray(res)) return []
    return res
  }
  static sortItems = (order, property) => (a, b) => {
    const itemA = _.get(a, property)
    const itemB = _.get(b, property)
    if(order === 'asc'){
      return itemA < itemB ? -1 : 1
    } else {
      return itemA < itemB ? 1 : -1
    }
  }
  static filterPermissions = (Items, filter) => {
    filter = filter.toLowerCase();
    if(!filter) return Items
    if(Items && Items.length > 0){
      const filteredItems = Items.filter((item) => item.description?.toLowerCase().includes(filter) || item.permission?.toLowerCase().includes(filter))
      return filteredItems
    }
  }
  
}