import React, { useEffect } from "react"
import { useOktaAuth } from '@okta/okta-react'
import { observer } from "mobx-react"
import { useStores } from '../../hooks/useStores'
import Button from  "@material-ui/core/Button" 
import DropdownMenu from "./DropdownMenu"
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import dropdownIcon from '../../assets/dropdown.png'

const ROUTES_TO_SHOW_SELECTORS = [ 'templates', 'permissions' ]

const NavigationBar = observer(() =>  {

  const { organizationStore } = useStores()

  const history = useHistory()
  const { oktaAuth, authState } = useOktaAuth()

  if (!authState) return null;

  const login = async () => history.push('/login')
  
  const logout = async () => oktaAuth.signOut()
    
  useEffect(async () => {
    await organizationStore.fetchOrgs()
  }, [])

  const parsedUrl = window.location.href ? window.location.href.split('/') : null
  const route = parsedUrl && parsedUrl.length > 0 ? parsedUrl[parsedUrl.length -1] : null
  const showSelectors = route ? ROUTES_TO_SHOW_SELECTORS.includes(route) : false

  return (
    <div className="nav-bar">
      <div className="left">
        <div className="title"> 
          <Link to={'/'}> {(process.env.ENVIRONMENT_PRETTY_NAME ? `${process.env.ENVIRONMENT_PRETTY_NAME} - ` : '') + 'Toolkit'} </Link>
        </div>
        <div className="links">
          <Link to={'/templates'}>{"Templates"}</Link>
          <Link to={'/permissions'}>{"Permissions"}</Link>
          <Link to={'/multi-org-permissions'}>{"Multi-Org Permissions"}</Link>
        </div>
      </div>
      <div className="right">
        {
          showSelectors ?
          <div className="organization-selection">
            <DropdownMenu 
              selected={organizationStore.selected}
              items={organizationStore.orgNames(route)}
              setSelected={organizationStore.setSelected}
              icon={dropdownIcon}
            />
          </div> :
          null
        }
        <div className="login-logout">
          { 
            authState.isAuthenticated ?
              <Button className="logout_button" onClick={logout}>Logout</Button> :
              <Button className="login_button" onClick={login}>Login</Button>
          }
        </div>
      </div>
    </div>
  )
})

export default NavigationBar