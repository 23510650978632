import React, { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Requests } from "../utilities/Requests"
import endpoints from '../../endpoints'
import { isArray } from "lodash"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@material-ui/core/Switch'
import { Button, Grid } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import _ from 'lodash'
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@material-ui/core/CircularProgress'

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const Status = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS'
};

const PERMISSIONS_BASE_URL = endpoints.MOBILE_APP_API

const SuccessfulSaveAlert = () => {
  return (
    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
      You successfully saved your changes.
    </Alert>
  )
}

const FailedSaveAlert = () => {
  return (
    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
      Something went wrong and your changes were not saved.
    </Alert>
  )
}

const LoadingSpinner = () => {
  return (
    <Grid item xs={12} md={6} className="list" sx={{ width: '100px', height: 400, margin: '0 auto' }}>
      <div className="loading-group">
        <CircularProgress disableShrink className="loading" />
      </div>
    </Grid>
  )
}

const updateIncludePermissions = (currentInclude, fflag, updateEnabledTo) => {

  // list can either have fflag or not have fflag

  // has fflag
  // if has fflag and enabled set to false, remove fflag
  // if has fflag and set to true, do nothing

  // does not have fflag
  // if does not have fflag and enabled set to false, do nothing
  // if does not have fflag and enabled set to true, add fflag

  if (currentInclude.includes(fflag)) {

    if (!updateEnabledTo) {
      return currentInclude.filter((item) => item !== fflag)
    } else {
      return currentInclude
    }

  } else {

    if (!updateEnabledTo) {
      return currentInclude
    } else {

      return [...currentInclude, fflag]
    }
  }

}

const updateExcludePermissions = (currentExclude, fflag, updateEnabledTo) => {

  // list can either have fflag or not have fflag

  // has fflag in exclude
  // if has fflag and enabled set to false, add fflag ??? or do nothing
  // if has fflag and set to true, remove fflag

  // does not have fflag in exclude
  // if does not have fflag and enabled set to false, add fflag ??? or do nothing?
  // if does not have fflag and enabled set to true, do nothing

  if (currentExclude.includes(fflag)) {

    if (!updateEnabledTo) {
      return currentExclude
    } else {
      return currentExclude.filter((item) => item !== fflag)
    }

  } else {

    if (!updateEnabledTo) {
      return currentExclude
    } else {

      return currentExclude
    }
  }

}

const MultiOrgPermissions = () => {
  const [featureFlag, setFeatureFlag] = useState('');
  const [originalPermissionsData, setOriginalPermissionsData] = useState([]);
  const [orgPermissionsData, setOrgPermissionsData] = useState([]);
  const [orgOriginalPermissionsDisplayData, setOrgOriginalPermissionsDisplayData] = useState([]);
  const [orgPermissionsDisplayData, setOrgPermissionsDisplayData] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [saveStatus, setSaveStatus] = useState('')


  useEffect(async () => {

    const fetchPermissions = async () => {
      try {
        const url = `${PERMISSIONS_BASE_URL}/system-permissions`;
        const res = await Requests.authRequest(url, 'GET');

        if (!res || !Array.isArray(res)) {
          setPermissionsList([]);
          return;
        }

        const permissionListResult = res
          .map(item => item.permission)
          .sort(); // Sort alphabetically

        setPermissionsList(permissionListResult);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setPermissionsList([]);
      } finally {
        setIsDataLoading(false);
        setSaveStatus('');
      }
    };

    fetchPermissions();

  }, [])

  const originalPermissionLookup = (permissions, idx) => {

    if (permissions && orgOriginalPermissionsDisplayData[idx].id == permissions.id
      && orgOriginalPermissionsDisplayData[idx].featureFlag == permissions.featureFlag
    ) {
      return orgOriginalPermissionsDisplayData[idx].enabled
    }
    return 'Data is incorrect'

  }

  const handleToggleAll = (value) => {
    // set all the toggles to true
    // update the orgPermissionData with the updated values
    // this is the object that will be sent to the backend to be persisted

    const orgObjectToUpdate = _.cloneDeep(orgPermissionsData)

    for (const org of Object.values(orgObjectToUpdate)) {
      org.include = updateIncludePermissions(org.include, featureFlag, value)
      org.exclude = updateExcludePermissions(org.exclude, featureFlag, value)
    }

    setOrgPermissionsData(orgObjectToUpdate)

    const updatedOrgPermissionsDisplayData = orgPermissionsDisplayData.map((org) => {
      return { ...org, enabled: value }
    })
    setOrgPermissionsDisplayData(updatedOrgPermissionsDisplayData)
  }

  const handleToggleChange = (event, row) => {
    // set the orPermissionsDisplayData with updated value
    const updatedOrgPermissionsDisplayData = orgPermissionsDisplayData.map((org) => {
      if (org.id === row.id) {
        return { ...org, enabled: event.target.checked }
      }
      return org
    })

    setOrgPermissionsDisplayData(updatedOrgPermissionsDisplayData)

    // update the orgPermissionData with the updated values
    // this is the object that will be sent to the backend to be persisted
    const orgObjectToUpdate = _.cloneDeep(orgPermissionsData[row.id])

    orgObjectToUpdate.include = updateIncludePermissions(orgObjectToUpdate.include, featureFlag, event.target.checked)
    orgObjectToUpdate.exclude = updateExcludePermissions(orgObjectToUpdate.exclude, featureFlag, event.target.checked)

    setOrgPermissionsData({ ...orgPermissionsData, [row.id]: orgObjectToUpdate })

  }

  const getOrgPermissions = async () => {
    setIsDataLoading(true)
    const orgRrl = `${endpoints.WEB_APP}/api/organization`
    const organizations = await Requests.authRequest(orgRrl, 'GET')
    const allOrgs = (organizations === undefined || !isArray(organizations)) ? [] : organizations

    const orgPermissionList = {}
    for (const org of allOrgs) {
      const orgId = org._id
      const orgName = org.name
      const permissionsUrl = `${PERMISSIONS_BASE_URL}/orgs/${orgName}/permissions`
      let res = await Requests.authRequest(permissionsUrl, 'GET')
      if (res) {
        orgPermissionList[orgId] = ({ orgId, orgName, include: res.includePerms || [], exclude: res.excludePerms || [] })
      }
    }

    setOrgPermissionsData(orgPermissionList)
    setOriginalPermissionsData(orgPermissionList)
    setIsDataLoading(false)
    return orgPermissionList
  }

  const formatOrgPermissions = (featureFlag, orgPermissionResult) => {
    const orgPermissionsDisplay = []

    Object.values(orgPermissionResult).map((org) => {
      const enabledFeatureFlags = org.include
      orgPermissionsDisplay.push({ id: org.orgId, name: org.orgName, featureFlag, enabled: enabledFeatureFlags.includes(featureFlag) })
    })

    setOrgOriginalPermissionsDisplayData(orgPermissionsDisplay)
    setOrgPermissionsDisplayData(orgPermissionsDisplay)
  }

  const handleChangePermissions = async (event) => {
    setSaveStatus('')
    setFeatureFlag(event.target.value);
    const orgPermissionResult = await getOrgPermissions()
    formatOrgPermissions(event.target.value, orgPermissionResult)
  };

  const handleUpdateOrgPermissions = async () => {
    // iterate through the orgs and their permissions and send update request to orgs that have changes
    // check changes by comparing the original permissions with current permissions

    const userId = null
    let changedOrgObjects = [];
    if (Object.keys(orgPermissionsData)) {
      changedOrgObjects = Object.values(orgPermissionsData).map((org) => {
        // each org has the orgId, orgName, include, exclude
        const orgName = org.orgName
        const orgId = org.orgId
        const permissions = { includes: org.include, excludes: org.exclude }
        const name = featureFlag
        let value = ''

        // get the change by comparing current feature flag permissions with original permissions
        // if the value was is in original
        // not in current, then it was removed
        // is in current, then no action
        if (originalPermissionsData[orgId].include.includes(featureFlag) && !org.include.includes(featureFlag)) {
          value = 'Removed'

          // if value was not in original
          // not in current, then no action
          // is in current, then it was added
        } else if (!originalPermissionsData[orgId].include.includes(featureFlag) && org.include.includes(featureFlag)) {
          value = 'Added'
        }

        if (value === 'Added' || value === 'Removed') {
          return { orgName, orgId, permissions, changes: [{ name, value }] }
        }
      })
    }

    for (const org of changedOrgObjects) {
      if (!org) continue
      const { orgName, orgId, permissions, changes } = org
      const permissionsUrl = `${PERMISSIONS_BASE_URL}/orgs/${orgName}/permissions`

      try {
        await Requests.authRequest(permissionsUrl, 'PATCH', {
          ...permissions,
          orgName,
          orgId,
          userId,
          changes
        })

      } catch (error) {
        console.error('Error updating org permissions', error)
        setSaveStatus(Status.FAILED)
      }
    }
    setSaveStatus(Status.SUCCESS)
    await getOrgPermissions()
  };

  const handleCancelOrgPermissions = () => {
    // revert all changes to permissions
    // set the permissions to how they were before user made edits to permissions
    setOrgPermissionsData(originalPermissionsData)
    formatOrgPermissions(featureFlag, originalPermissionsData)
  }

  return (
    <div className='organization-settings-container'>
      <h1 className='title'> Multi-Org Permissions </h1>
      {
        saveStatus === Status.SUCCESS ? <SuccessfulSaveAlert /> : saveStatus === Status.FAILED ? <FailedSaveAlert /> : null
      }
      <Alert severity="warning" style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
        Select the feature flag you want to update for multiple organizations.
        Selecting a another feature flag will overwrite any unsaved changes with the state from the DB. <br />

        All changes made to the permissions using the toggles are NOT saved until you click the Save button. <br />
      </Alert>
      {!isDataLoading && !!permissionsList ?
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="lg">
            <div className='selection-and-button' style={{ width: '100%', maxWidth: '1200px', display: 'flex', margin: '0 auto' }} >
              <div className='selection-container' style={{ width: '100%', maxWidth: '1200px', margin: '10px auto' }} >
                <Box sx={{ maxWidth: '600px' }}>
                  <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Feature Flag</InputLabel>
                    <Select
                      value={featureFlag}
                      label="Feature Flag"
                      onChange={handleChangePermissions}
                    >
                      {permissionsList.map((item) => {
                        return <MenuItem value={item} key={`key-${item}`}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="permission-buttons">
                <Button variant="contained" color="default" size="small" disabled={false} onClick={() => handleToggleAll(true)}>
                  Toggle All ON
                </Button>
                <Button variant="contained" color="default" size="small" disabled={false} onClick={() => handleToggleAll(false)}>
                  Toggle All OFF
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className="cancel-button"
                  disabled={false}
                  onClick={handleCancelOrgPermissions}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  className="save-button"
                  disabled={false}
                  onClick={handleUpdateOrgPermissions}
                >Save</Button>
              </div>
            </div>
            <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '1200px', maxHeight: 400, margin: '5px auto', overflow: 'auto' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Organization</TableCell>
                    <TableCell align="right">Feature Flag</TableCell>
                    <TableCell align="right">Originally Enabled</TableCell>
                    <TableCell align="right">Currently Enabled</TableCell>
                    <TableCell align="right">Toggle</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgOriginalPermissionsDisplayData && orgPermissionsDisplayData && orgPermissionsDisplayData.map((row, idx) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.featureFlag}</TableCell>
                      <TableCell align="right">{`${originalPermissionLookup(row, idx)}`}</TableCell>
                      <TableCell align="right">{`${row.enabled}`}</TableCell>
                      <TableCell align="right">{<Switch checked={row.enabled} onChange={() => handleToggleChange(event, row)} inputProps={{ 'aria-label': 'controlled' }} />}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </React.Fragment>
        : <div style={{ width: '100%', maxWidth: '1200px', margin: '10px auto' }}><LoadingSpinner /> </div>}
    </div>
  )
}

export default MultiOrgPermissions