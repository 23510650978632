import { makeAutoObservable } from "mobx"
import { OrganizationAPI } from "../utilities/OrganizationsHelper"
import endpoints from '../../endpoints'

const DEFAULT = "DEFAULT"

export default class Organizations {
  
  title = 'Organizations'
  list = []
  selected = DEFAULT
  initialFetch = false
  orgMap = new Map();
  selectedOrgId = DEFAULT

  constructor(){
    makeAutoObservable(this)
  }

  fetchOrgs = async () => {
    const orgs = await OrganizationAPI.fetchAllOrganizations(endpoints.WEB_APP)
    this.list = orgs ? orgs : []
    this.initialFetch = true
  }

  setSelected = selected => {
    this.selectedOrgId = this.orgMap.get(selected)
    this.selected = selected
  }

  orgNames(route){
    let names = this.list ? this.list.map(org => org.name) : []
    names.sort()
    if (route === 'templates') names.unshift(DEFAULT)
    for (const org of this?.list) {
      this.orgMap.set(org?.name, org?._id || org?.id)
    }
    if (this.selected) {
      this.selectedOrgId = this.orgMap.get(this.selected)
    }
    return names
  }
}