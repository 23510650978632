import React from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import Home from './Home'
import Login from './Login'
import Templates from './Templates'
import Permissions from './Permissions'
import MultiOrgPermissions from './MultiOrgPermissions' 
import { withNavBar } from '../components/reuseable/withNavBar'
import { oktaAuthConfig, oktaSignInConfig } from '../okta_config'
console.log(oktaAuthConfig)

const oktaAuth = new OktaAuth(oktaAuthConfig)

const AppWithRouterAccess = () => {
  const history = useHistory()

  const customAuthHandler = () => {
    history.push('/login')
  };
  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
      className='security'
    >
      <Switch className='switch'>
        <Route path='/login/callback' component={LoginCallback} /> {/* This needs to be the first route for login to work as expected */}
        <Route path='/login' render={() => <Login config={oktaSignInConfig} />} />
        <SecureRoute exact={true}  path='/' component={withNavBar(Home)} />
        <SecureRoute path="/templates" component={withNavBar(Templates)} />
        <SecureRoute path="/permissions" component={withNavBar(Permissions)} />
        <SecureRoute path="/multi-org-permissions" component={withNavBar(MultiOrgPermissions)} />
      </Switch>
    </Security>
  )
}
export default AppWithRouterAccess
